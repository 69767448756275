import React, { Component, useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";
import JsBarcode from "jsbarcode";

import { FormWrapper, InputButton } from "../../../../../components/boda-boda/Forms";
import { modalTypes, ModalUI } from "../../../../../components/boda-boda/Modal/ModalUI";
import { getManifestListAPI, getOrdersListAPI, runSheetLineAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { callApi } from "../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { CustomLoader } from "../../../../../components/boda-boda/CommonElements/BaseElements";
import { printModalHelperFn } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { currency } from "../../../../../config/boda-boda/db_config/db_status.config";


class LastMileLabel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false,
        };
    }

    render() {
        const onClickFn = () => {
            this.setState({ isShow: true })
        };

        return (
            <React.Fragment>
                <InputButton
                    btnText={"Print"}
                    onClickBtnFn={() => onClickFn()}
                    startIcon={"mdi mdi-printer"}
                />

                <ModalUI
                    isOpen={this.state.isShow}
                    modalTitle={"Pick Up/Lastmile Runsheet"}
                    modalType={modalTypes.large}
                    modalBodyComponent={
                        <LastMilePrint row={this.props.lastMile} />
                    }
                    showHideToggleFn={() => this.setState({ isShow: false })}
                    modalFooterComponent={
                        <PrintLastMileBtn />
                    }
                />
            </React.Fragment>
        )
    }
}

const LastMilePrint = (props) => {
    const [deliveries, setDeliveries] = useState("fetching");
    const [pickups, setPickups] = useState("fetching");
    const [returns, setReturns] = useState("fetching");
    const formState = useSelector(state => state.FormData);

    useEffect(() => {
        if (get(formState, `${runSheetLineAPI.key}_print._onLoad`, true) === false) {

            async function loadManifest() {
                const deliveryManifestResponse = await callApi(`${getManifestListAPI.url}/all?type=DELIVERY&runSheetLastId=${get(props, 'row.id', '')}`)
                    .headers(true)
                    .method("get")
                    .send();

                if (deliveryManifestResponse._statue === true) {
                    if (get(deliveryManifestResponse, "data.data", []).length !== 0) {
                        const deliveryManifestId = get(deliveryManifestResponse, "data.data", "")[0].id;

                        const orderResponse = await callApi(`${getOrdersListAPI.url}/all?${get(props, 'row.status', "") === "EXPIRED" ? "lastExpiredManifestId" : "manifestId"}=${deliveryManifestId}&runSheetPrint=true`)
                            .headers(true)
                            .method("get")
                            .send();

                        if (orderResponse._statue === true) {
                            setDeliveries(get(orderResponse, "data.data", []))
                        }
                    } else {
                        setDeliveries([])
                    }
                }

                const merchantManifestResponse = await callApi(`${getManifestListAPI.url}/all?type=MERCHANT&${get(props, 'row.status', "") === "EXPIRED" ? "lastExpiredRunSheetLastId" : "runSheetLastId"}=${get(props, 'row.id', '')}`)
                    .headers(true)
                    .method("get")
                    .send();

                if (merchantManifestResponse._statue === true) {
                    setPickups(get(merchantManifestResponse, "data.data", []))
                }

                const returnManifestResponse = await callApi(`${getManifestListAPI.url}/all?type=RETURN&runSheetLastId=${get(props, 'row.id', '')}`)
                    .headers(true)
                    .method("get")
                    .send();

                if (returnManifestResponse._statue === true) {
                    if (get(returnManifestResponse, "data.data", []).length !== 0) {
                        const returnManifestId = get(returnManifestResponse, "data.data", "")[0].id;

                        const orderResponse = await callApi(`${getOrdersListAPI.url}/all?${get(props, 'row.status', "") === "EXPIRED" ? "lastExpiredManifestId" : "manifestId"}=${returnManifestId}&runSheetPrint=true`)
                            .headers(true)
                            .method("get")
                            .send();

                        if (orderResponse._statue === true) {
                            setReturns(get(orderResponse, "data.data", []))
                        }
                    } else {
                        setReturns([])
                    }
                }

            }

            loadManifest();

            JsBarcode("#RunsheetId", get(props, 'row.code', ""), {
                width: 1,
                height: 40,
                margin: 0,
                fontSize: 15
            });

            JsBarcode(".collectionID").init();
        }
    }, [
        get(formState, `${runSheetLineAPI.key}_print._onLoad`, true)
    ])

    return (
        <div id={"print_preview"}>
            <div className={"runSheetLinePrintWrapper"}>
                <FormWrapper
                    setGroupName={`${runSheetLineAPI.key}_print`}
                    onDestroyUnsetFormObject={true}
                >
                    <div className={"row"}>
                        <div className={"col-sm-6"}>
                            <dl className={"row"}>
                                <dd className={"col-sm-5"}>Runsheet Code</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.code`, "")}</dt>

                                <dd className={"col-sm-5"}></dd>
                                <dt className={"col-sm-7"}><img id="RunsheetId" /></dt>

                                <dd className={"col-sm-5 mt-3"}>Runsheet Name</dd>
                                <dt className={"col-sm-7 mt-3"}>{get(props, `row.name`, "")}</dt>

                                <dd className={"col-sm-5"}>Date</dd>
                                <dt className={"col-sm-7"}>
                                    {dateObjectToString(get(props, `row.runSheetAt`, ""), "dd-mm-YY")}
                                </dt>

                                <dd className={"col-sm-5"}>Assigned By</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.creator`, "")}</dt>

                                <dd className={"col-sm-5"}>Created At</dd>
                                <dt className={"col-sm-7"}>
                                    {dateObjectToString(get(props, `row.createdAt`, ""), "dd-mm-YY")}
                                </dt>
                            </dl>
                        </div>

                        <div className={"col-sm-6"}>
                            <dl className={"row"}>
                                <dd className={"col-sm-5"}>Vehicle No</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.vehiclePlate`, "")}</dt>

                                <dd className={"col-sm-5"}>Driver ID</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.selectedDriver[0].id`, "")}</dt>

                                <dd className={"col-sm-5"}>Driver Name</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.selectedDriver[0].name`, "")}</dt>

                                <dd className={"col-sm-5"}>Route ID</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.selectedRoute[0].id`, "")}</dt>

                                <dd className={"col-sm-5"}>Route Name</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.selectedRoute[0].name`, "")}</dt>

                                <dd className={"col-sm-5"}>Address</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.facility.location.address`, "")}</dt>
                            </dl>
                        </div>

                        <div className={"col-sm-6"}>
                        </div>

                        <div className="col-sm-6">
                            <label>Notes</label>
                            {
                                get(props, `row.notes`, []).length > 0 ? (
                                    <ul style={{ paddingLeft: "17px" }}>
                                        {
                                            get(props, `row.notes`, []).map((element, index) => {
                                                return (
                                                    <li key={index}>{element.note} <span style={{ fontWeight: "500" }}>By {element.name}</span></li>
                                                );
                                            })
                                        }
                                    </ul>
                                ) : (
                                    <div>None</div>
                                )
                            }
                        </div>
                    </div>

                    {
                        (pickups === "fetching" || deliveries === "fetching" || returns === "fetching") ? (
                            <div className={"col-sm-12"}>
                                <div className={"card mb-1"}>
                                    <div className={"card-body"}>
                                        <center>
                                            <CustomLoader />
                                        </center>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className={"row"}>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card mb-1">
                                                <div className="card-body">
                                                    <div className="row mb-3">
                                                        <div className="col-sm-6">
                                                            <h4 className="card-title">Pick Ups/Deliveries</h4>
                                                        </div>
                                                    </div>
                                                    <div className={"row"}>
                                                        <div className={"col-sm-6"}>
                                                            <dl className={"row"}>
                                                                <dd className={"col-sm-7"}>No of Pick Ups</dd>
                                                                <dt className={"col-sm-5"}>{pickups.length}</dt>

                                                                <dd className={"col-sm-7"}>No of Deliveries</dd>
                                                                <dt className={"col-sm-5"}>{deliveries.length}</dt>
                                                            </dl>
                                                        </div>
                                                        <div className={"col-sm-6"}>
                                                            <dl className={"row"}>
                                                                <dd className={"col-sm-7"}>No of Returns</dd>
                                                                <dt className={"col-sm-5"}>{returns.length}</dt>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                    <div className="table-rep-plugin">
                                                        <div
                                                            className="table-responsive mb-0"
                                                            data-pattern="priority-columns"
                                                        >
                                                            <table id="tech-companies-1" className="table table-striped print-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Pick Up ID / Consignment No</th>
                                                                        <th>Type</th>
                                                                        <th>Channel Order No</th>
                                                                        <th>Merchant/Customer Name</th>
                                                                        <th>Pickup/Delivery Address & City</th>
                                                                        <th>Contact Number</th>
                                                                        <th>Payment Type</th>
                                                                        <th>No of Packages</th>
                                                                        <th>{`COD Amount (${currency})`}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        (pickups.length === 0 && deliveries.length === 0 && returns.length === 0) ? (
                                                                            <tr>
                                                                                <td colSpan={5} className={"text-center"}>No results found</td>
                                                                            </tr>
                                                                        ) : (
                                                                            <Fragment>
                                                                                {
                                                                                    pickups.map((bodyData, index) => {
                                                                                        return (
                                                                                            <tr key={index}>
                                                                                                <td>{get(bodyData, "code", "-")}</td>
                                                                                                <td>{get(bodyData, "type", "-") == 'MERCHANT' ? 'PICKUP' : get(bodyData, "type", "-")}</td>
                                                                                                <td>-</td>
                                                                                                <td>{get(bodyData, "merchant.name", "")}</td>
                                                                                                <td>{get(bodyData, "locationPick.address", "-")},{get(bodyData, "locationPick.city.name", "-")}</td>
                                                                                                <td>{get(bodyData, "merchant.contacts[0].phone", "-")}</td>
                                                                                                <td>-</td>
                                                                                                <td>{get(bodyData, "publishedPackages", []).length}</td>
                                                                                                <td>-</td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                {
                                                                                    deliveries.map((bodyData, index) => {
                                                                                        return (
                                                                                            <tr key={index}>
                                                                                                <td>{get(bodyData, "consignmentNo", "-")}</td>
                                                                                                <td>DELIVERY</td>
                                                                                                <td>{get(bodyData, "channelOrderNo", "-")}</td>
                                                                                                <td>{get(bodyData, "name", "-")}</td>
                                                                                                <td>{get(bodyData, "shippingLocation.address", "-")},{get(bodyData, "shippingLocation.city.name", "-")}</td>
                                                                                                <td>{get(bodyData, "phone", "-")}</td>
                                                                                                <td>{get(bodyData, "paymentMethod", "-")}</td>
                                                                                                <td>{get(bodyData, "packages", "").length}</td>
                                                                                                <td>{get(bodyData, "amount", "-")}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                {
                                                                                    returns.map((bodyData, index) => {
                                                                                        return (
                                                                                            <tr key={index}>
                                                                                                <td>{get(bodyData, "consignmentNo", "-")}</td>
                                                                                                <td>RETURN</td>
                                                                                                <td>{get(bodyData, "channelOrderNo", "-")}</td>
                                                                                                <td>{get(bodyData, "name", "-")}</td>
                                                                                                <td>{get(bodyData, "shippingLocation.address", "-")},{get(bodyData, "shippingLocation.city.name", "-")}</td>
                                                                                                <td>{get(bodyData, "phone", "-")}</td>
                                                                                                <td>{get(bodyData, "paymentMethod", "-")}</td>
                                                                                                <td>{get(bodyData, "packages", "").length}</td>
                                                                                                <td>{get(bodyData, "amount", "-")}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Fragment>
                                                                        )
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                </FormWrapper>
            </div>
        </div>
    )
}

const PrintLastMileBtn = () => {
    return (
        <InputButton
            btnText={"Print"}
            onClickBtnFn={() => printModalHelperFn("print_preview")}
        />
    )
}

export {
    PrintLastMileBtn,
    LastMilePrint,
    LastMileLabel
}

