import { put, call } from 'redux-saga/effects';
import { get } from "lodash";
import { setPageLoader, setFlashMessage } from "../ui/actions";
import { callApi } from "../../../helpers/boda-boda/common-helpers/callApi.helpers";
import {
    getManifestListAPI, runSheetLastAPI, runSheetLastLocationAPI,
    getPackageListAPI, getOrdersListAPI
} from "../../../config/boda-boda/apiUrls.config";
import validateMethod from '../../../helpers/boda-boda/validation';
import { setFormErrorFn } from '../form/actions';
import { isEmptyValue } from "../../../helpers/boda-boda/common-helpers/common.helpers";
import { resetDataTable } from "../core/actions";

const emptyFn = (...para) => undefined;

function* createRunSheetLast(data) {
    const validationResponse = yield call(() => validateMethod(
        get(data, 'payload', {}),
        {
            name: "Name",
            runSheetAt: "Date",
            vehiclePlate: "Vehicle Number",
        },
        {
            name: "required",
            runSheetAt: "required",
            vehiclePlate: "required"
        },
        {},
    )
    );

    if (validationResponse._status === false) {
        yield put(setFormErrorFn(`${runSheetLastAPI.key}_create`, validationResponse.errors));

        if (get(data, 'payload.route', []).length === 0) {
            yield put(setFlashMessage({
                status: true,
                message: "You should have attach a route",
                type: "danger",
            }));
        }

        if (get(data, 'payload.driver', []).length === 0) {
            yield put(setFlashMessage({
                status: true,
                message: "You should have attach a driver",
                type: "danger",
            }));
        }
    } else {
        yield put(setFormErrorFn(`${runSheetLastAPI.key}_create`, []));

        if (get(data, 'payload.driver', []).length === 0 || get(data, 'payload.route', []).length === 0) {
            if (get(data, 'payload.route', []).length === 0) {
                yield put(setFlashMessage({
                    status: true,
                    message: "You should have attach a route",
                    type: "danger",
                }));
            }

            if (get(data, 'payload.driver', []).length === 0) {
                yield put(setFlashMessage({
                    status: true,
                    message: "You should have attach a driver",
                    type: "danger",
                }));
            }
        } else {
            yield put(setPageLoader(true));

            let apiRequestBody = {
                name: get(data, 'payload.name', ""),
                runSheetAt: get(data, 'payload.runSheetAt'),
                vehiclePlate: get(data, 'payload.vehiclePlate', ""),
                driver: {
                    id: get(data, 'payload.driver[0].id')
                },
                routeLast: {
                    id: get(data, 'payload.route[0].id')
                },
                status: "ACTIVE",
            }

            if (!isEmptyValue(get(data, 'payload.notes', ""))) {
                apiRequestBody["notes"] = [
                    {
                        "name": get(data, "payload.authUser.name", ""),
                        "at": new Date(),
                        "note": get(data, 'payload.notes', ""),
                        "createdBy": get(data, "payload.authUser.id"),
                    }
                ]
            }

            const response = yield call(() => callApi(runSheetLastAPI.url).headers(true).method("post").body(apiRequestBody).send());

            yield put(setPageLoader(false));

            if (response._statue === true) {
                yield put(setFlashMessage({
                    status: true,
                    message: "Runsheet has successfully created",
                    type: "success",
                }));

                get(data, "payload.history.push", emptyFn)(`/runsheets-last/edit/${get(response, "data.data.id")}`);
            } else {
                if (get(response, "data.code", null) === 400) {
                    yield put(setFormErrorFn(`${runSheetLastAPI.key}_create`, get(response, "data.errors", [])));
                    yield put(setFlashMessage({
                        status: true,
                        message: get(response, "data.errors[0].error", []),
                        type: "danger",
                    }));
                } else if (get(response, "data.statusCode", null) === 403) {
                    yield put(setFlashMessage({
                        status: true,
                        message: "Permission denied to access",
                        type: "danger",
                    }));
                } else {
                    yield put(setFlashMessage({
                        status: true,
                        message: "Something went wrong...!!",
                        type: "danger",
                    }));
                }
            }
        }
    }
}

function* editRunSheetLast(data) {
    const validationResponse = yield call(() => validateMethod(
        get(data, 'payload', {}),
        {
            name: "Name",
            runSheetAt: "Date",
            vehiclePlate: "Vehicle Number",
            status: "Status",
        },
        {
            name: "required",
            runSheetAt: "required",
            vehiclePlate: "required",
            status: "required",
        },
        {},
    )
    );

    if (validationResponse._status === false) {
        yield put(setFormErrorFn(`${runSheetLastAPI.key}_edit`, validationResponse.errors));

        if (get(data, 'payload.selectedRoute', []).length === 0) {
            yield put(setFlashMessage({
                status: true,
                message: "You should have attach a route",
                type: "danger",
            }));
        }

        if (get(data, 'payload.selectedDriver', []).length === 0) {
            yield put(setFlashMessage({
                status: true,
                message: "You should have attach a driver",
                type: "danger",
            }));
        }
    } else {
        yield put(setFormErrorFn(`${runSheetLastAPI.key}_edit`, []));

        if (get(data, 'payload.selectedDriver', []).length === 0 || get(data, 'payload.selectedRoute', []).length === 0) {
            if (get(data, 'payload.selectedRoute', []).length === 0) {
                yield put(setFlashMessage({
                    status: true,
                    message: "You should have attach a route",
                    type: "danger",
                }));
            }

            if (get(data, 'payload.selectedDriver', []).length === 0) {
                yield put(setFlashMessage({
                    status: true,
                    message: "You should have attach a driver",
                    type: "danger",
                }));
            }
        } else {
            yield put(setPageLoader(true));

            let apiRequestBody = {
                name: get(data, 'payload.name', ""),
                runSheetAt: get(data, 'payload.runSheetAt'),
                vehiclePlate: get(data, 'payload.vehiclePlate', ""),
                driver: {
                    id: get(data, 'payload.selectedDriver[0].id')
                },
                routeLast: {
                    id: get(data, 'payload.selectedRoute[0].id')
                },
                status: get(data, 'payload.status', ""),
            }

            if (!isEmptyValue(get(data, 'payload.note', ""))) {
                apiRequestBody["notes"] = [
                    ...get(data, 'payload.notes', []),
                    {
                        "name": get(data, "payload.authUser.name", ""),
                        "at": new Date(),
                        "note": get(data, 'payload.note', ""),
                        "createdBy": get(data, "payload.authUser.id"),
                    }
                ]
            }

            const response = yield call(() => callApi(`${runSheetLastAPI.url}/${get(data, 'payload.id')}`)
                .headers(true).method("put")
                .body(apiRequestBody)
                .send()
            );

            yield put(setPageLoader(false));

            if (response._statue === true) {
                yield put(setFlashMessage({
                    status: true,
                    message: "Runsheet has successfully updated",
                    type: "success",
                }));

                get(data, "payload.history.push", emptyFn)("/runsheets-last");
            } else {
                if (get(response, "data.code", null) === 400) {
                    yield put(setFormErrorFn(`${runSheetLastAPI.key}_edit`, get(response, "data.errors", [])));
                    yield put(setFlashMessage({
                        status: true,
                        message: get(response, "data.errors[0].error", []),
                        type: "danger",
                    }));
                } else if (get(response, "data.statusCode", null) === 403) {
                    yield put(setFlashMessage({
                        status: true,
                        message: "Permission denied to access",
                        type: "danger",
                    }));
                } else {
                    yield put(setFlashMessage({
                        status: true,
                        message: get(response, "data.message", "Something went wrong...!!"),
                        type: "danger",
                    }));
                }
            }
        }
    }
}

function* addPickups(data) {
    yield put(setPageLoader(true));

    let runSheetLastLocationAPIBody = {
        "runSheetLast": {
            "id": get(data, "payload.runSheetLast")
        },
        "location": {
            "id": get(data, "payload.manifest.locationPickId")
        },
        "type": "PICKUP",
        "status": "ON_THE_WAY"
    }

    if (get(data, "payload.force", false) === true) {
        runSheetLastLocationAPIBody["force"] = true;
    }

    const runSheetLastLocationAPIResponse = yield call(() => callApi(`${runSheetLastLocationAPI.url}`)
        .headers(true)
        .method("post")
        .body(runSheetLastLocationAPIBody)
        .send()
    );

    if (runSheetLastLocationAPIResponse._statue === false) {
        yield put(setPageLoader(false));

        if (get(runSheetLastLocationAPIResponse, "data.statusCode", null) === 406) {
            get(data, "payload.showModalFn", emptyFn)("showModal");
        }
    } else {
        let response = yield call(() => callApi(`${getManifestListAPI.url}/${get(data, "payload.manifest.id")}`)
            .headers(true)
            .method("put")
            .body({
                "driver": {
                    "id": get(data, "payload.driver")
                },
                "runSheetLast": {
                    "id": get(data, "payload.runSheetLast")
                }
            })
            .send()
        );

        if (response._statue === true) {
            yield put(resetDataTable(`${getManifestListAPI.key}_runSheetLast`));
            yield put(resetDataTable(`${getManifestListAPI.key}_runSheetLast_selected`));

            get(data, "payload.closeModalFn", emptyFn)();

            yield put(setPageLoader(false));
            yield put(setFlashMessage({
                status: true,
                message: "Pickup has successfully added",
                type: "success"
            }));
        } else {
            if (get(response, 'data.statusCode') === 400) {
                yield put(setFlashMessage({
                    status: true,
                    message: get(response, 'data.message', 'Something went wrong!'),
                    type: "danger"
                }));
            }
        }
    }

    yield put(setPageLoader(false));
}

function* removePickups(data) {
    yield put(setPageLoader(true));

    const response = yield call(() => callApi(`${getManifestListAPI.url}/${get(data, "payload.manifest.id")}`)
        .headers(true)
        .method("put")
        .body({
            "runSheetLast": null,
            "driver": null
        })
        .send()
    );

    yield call(() => callApi(`${runSheetLastAPI.url}/${get(data, "payload.runSheetLast")}/location/${get(data, "payload.manifest.locationPickId")}`)
        .headers(true)
        .method("delete")
        .send()
    );

    if (response._statue === true) {
        yield put(resetDataTable(`${getManifestListAPI.key}_runSheetLast`));
        yield put(resetDataTable(`${getManifestListAPI.key}_runSheetLast_selected`));

        yield put(setPageLoader(false));
        yield put(setFlashMessage({
            status: true,
            message: "Pickup has successfully removed",
            type: "success"
        }));
    }

    yield put(setPageLoader(false));
}

function* addDeliveries(data) {
    if (get(data, "payload.driver", null) === null) {
        yield put(setFlashMessage({
            status: true,
            message: "You should have attach a driver",
            type: "danger",
        }));
    } else {
        yield put(setPageLoader(true));

        let runSheetLastLocationAPIBody = {
            "runSheetLast": {
                "id": get(data, "payload.runSheetLast")
            },
            "location": {
                "id": get(data, "payload.package.locationFinalId")
            },
            "type": "DELIVERY",
            "status": "ON_THE_WAY"
        }

        if (get(data, "payload.force", false) === true) {
            runSheetLastLocationAPIBody["force"] = true;
        }

        const runSheetLastLocationAPIResponse = yield call(() => callApi(`${runSheetLastLocationAPI.url}`)
            .headers(true)
            .method("post")
            .body(runSheetLastLocationAPIBody)
            .send()
        );

        if (runSheetLastLocationAPIResponse._statue === false) {
            yield put(setPageLoader(false));

            if (get(runSheetLastLocationAPIResponse, "data.statusCode", null) === 406) {
                get(data, "payload.showModalFn", emptyFn)("showDeliveryModal");
            } else if (get(runSheetLastLocationAPIResponse, "data.statusCode", null) === 400) {
                yield put(setFlashMessage({
                    status: true,
                    message: get(runSheetLastLocationAPIResponse, "data.message", "Something went wrong!"),
                    type: "danger"
                }));
            }
        } else {
            let deliveryManifestResponse = yield call(() => callApi(`${getManifestListAPI.url}/${get(data, "payload.manifest.id")}`)
                .headers(true)
                .method("put")
                .body({
                    "driver": {
                        "id": get(data, "payload.driver.id")
                    }
                })
                .send()
            );

            if (deliveryManifestResponse._statue === true) {
                let response = yield call(() => callApi(`${getPackageListAPI.url}/${get(data, "payload.package.id")}`)
                    .headers(true)
                    .method("put")
                    .body({
                        "manifest": {
                            "id": get(data, "payload.manifest.id")
                        },
                        "locationPick": {
                            "id": get(data, "payload.package.locationCurrentId")
                        },
                        "locationDrop": {
                            "id": get(data, "payload.package.locationFinalId")
                        }
                    })
                    .send()
                );

                if (response._statue === true) {
                    // yield put(resetDataTable(`${getPackageListAPI.key}_runSheetLast`));
                    // yield put(resetDataTable(`${getOrdersListAPI.key}_runSheetLast_selected`));

                    get(data, "payload.refreshDeliveryTabFn", emptyFn)();
                    get(data, "payload.closeModalFn", emptyFn)();

                    yield put(setPageLoader(false));
                    yield put(setFlashMessage({
                        status: true,
                        message: "Delivery has successfully added",
                        type: "success"
                    }));
                }
            }
        }
    }

    yield put(setPageLoader(false));
}

function* removeDeliveries(data) {
    yield put(setPageLoader(true));

    yield call(() => callApi(`${runSheetLastAPI.url}/${get(data, "payload.runSheetLast")}/location/${get(data, "payload.order.shippingLocationId")}`)
        .headers(true)
        .method("delete")
        .send()
    );

    const response = yield call(() => callApi(`${getOrdersListAPI.url}/${get(data, "payload.order.id")}/package`)
        .headers(true)
        .method("put")
        .body({
            "manifest": null,
            "locationPick": null,
            "locationDrop": null,
        })
        .send()
    );

    if (response._statue === true) {
        // yield put(resetDataTable(`${getPackageListAPI.key}_runSheetLast`));
        // yield put(resetDataTable(`${getOrdersListAPI.key}_runSheetLast_selected`));

        get(data, "payload.refreshDeliveryTabFn", emptyFn)();

        yield put(setPageLoader(false));
        yield put(setFlashMessage({
            status: true,
            message: "Consignment has successfully removed",
            type: "success"
        }));
    }

    yield put(setPageLoader(false));
}

function* addReturns(data) {
    if (get(data, "payload.driver", null) === null) {
        yield put(setFlashMessage({
            status: true,
            message: "You should have attach a driver",
            type: "danger",
        }));
    } else {
        yield put(setPageLoader(true));

        let runSheetLastLocationAPIBody = {
            "runSheetLast": {
                "id": get(data, "payload.runSheetLast")
            },
            "location": {
                "id": get(data, "payload.package.locationReturnId")
            },
            "type": "RETURN",
            "status": "ON_THE_WAY"
        }

        if (get(data, "payload.force", false) === true) {
            runSheetLastLocationAPIBody["force"] = true;
        }

        const runSheetLastLocationAPIResponse = yield call(() => callApi(`${runSheetLastLocationAPI.url}`)
            .headers(true)
            .method("post")
            .body(runSheetLastLocationAPIBody)
            .send()
        );

        if (runSheetLastLocationAPIResponse._statue === false) {
            yield put(setPageLoader(false));

            if (get(runSheetLastLocationAPIResponse, "data.statusCode", null) === 406) {
                get(data, "payload.showModalFn", emptyFn)("showReturnModal");
            } else if (get(runSheetLastLocationAPIResponse, "data.statusCode", null) === 400) {
                yield put(setFlashMessage({
                    status: true,
                    message: get(runSheetLastLocationAPIResponse, "data.message", "Something went wrong!"),
                    type: "danger"
                }));
            }
        } else {
            let returnManifestResponse = yield call(() => callApi(`${getManifestListAPI.url}/${get(data, "payload.manifest.id")}`)
                .headers(true)
                .method("put")
                .body({
                    "driver": {
                        "id": get(data, "payload.driver.id")
                    }
                })
                .send()
            );

            if (returnManifestResponse._statue === true) {
                let response = yield call(() => callApi(`${getPackageListAPI.url}/${get(data, "payload.package.id")}`)
                    .headers(true)
                    .method("put")
                    .body({
                        "manifest": {
                            "id": get(data, "payload.manifest.id")
                        },
                        "locationPick": {
                            "id": get(data, "payload.package.locationCurrentId")
                        },
                        "locationDrop": {
                            "id": get(data, "payload.package.locationReturnId")
                        }
                    })
                    .send()
                );

                if (response._statue === true) {
                    // yield put(resetDataTable(`${getPackageListAPI.key}_runSheetLast`));
                    // yield put(resetDataTable(`${getOrdersListAPI.key}_runSheetLast_selected`));

                    get(data, "payload.refreshReturnTabFn", emptyFn)();
                    get(data, "payload.closeModalFn", emptyFn)();

                    yield put(setPageLoader(false));
                    yield put(setFlashMessage({
                        status: true,
                        message: "Return has successfully added",
                        type: "success"
                    }));
                }
            }
        }
    }

    yield put(setPageLoader(false));
}

function* removeReturns(data) {
    yield put(setPageLoader(true));

    yield call(() => callApi(`${runSheetLastAPI.url}/${get(data, "payload.runSheetLast")}/location/${get(data, "payload.order.returnLocationId")}`)
        .headers(true)
        .method("delete")
        .send()
    );

    const response = yield call(() => callApi(`${getOrdersListAPI.url}/${get(data, "payload.order.id")}/package`)
        .headers(true)
        .method("put")
        .body({
            "manifest": null,
            "locationPick": null,
            "locationDrop": null,
        })
        .send()
    );

    if (response._statue === true) {
        // yield put(resetDataTable(`${getPackageListAPI.key}_runSheetLast`));
        // yield put(resetDataTable(`${getOrdersListAPI.key}_runSheetLast_selected`));

        get(data, "payload.refreshReturnTabFn", emptyFn)();

        yield put(setPageLoader(false));
        yield put(setFlashMessage({
            status: true,
            message: "Consignment has successfully removed",
            type: "success"
        }));
    }

    yield put(setPageLoader(false));
}

export {
    createRunSheetLast,
    editRunSheetLast,
    addPickups,
    removePickups,
    addDeliveries,
    removeDeliveries,
    addReturns,
    removeReturns,
}
